.js .preloader {
  @apply fixed h-full w-full top-0 left-0 bg-white dark:bg-[#1D2E4A] flex flex-col justify-center items-center z-[3000];
}

.js .preloader div {
  --spinner-outer-size: 56px;
  --spinner-inner-size: 52px;
  --spinner-glow-size: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: var(--spinner-outer-size);
  height: var(--spinner-outer-size);
}

.js .preloader div::before {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 50% 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.025) 33%,
    rgb(43, 172, 222, 100%)
  );
  filter: drop-shadow(0 0 var(--spinner-glow-size) rgba(255, 255, 255, 0.25));
  animation: rotation 0.6s linear infinite;
}

.js .preloader div::after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--spinner-inner-size);
  height: var(--spinner-inner-size);
  border-radius: 50%;
  @apply bg-white dark:bg-[#1d2e4a];
}

.js .preloader div span {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
