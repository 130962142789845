.dark .glow-blue {
  filter: drop-shadow(0px 0px 64px #1988fa);
  -webkit-filter: drop-shadow(0px 0px 64px #1988fa);
  -webkit-transform: translateZ(0); /* Safari Clipping Fix */
}

.glow-blue {
  filter: drop-shadow(0px 0px 64px rgba(0,0,0,0.5));
  -webkit-filter: drop-shadow(0px 0px 64px rgba(0,0,0,0.5));
  -webkit-transform: translateZ(0); /* Safari Clipping Fix */
}

.dreamy-shadow {
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 
    0 1px 2px rgba(0, 0, 0, 0.02), 
    0 2px 4px rgba(0, 0, 0, 0.02),
    0 4px 8px rgba(0, 0, 0, 0.02), 
    0 8px 16px rgba(0, 0, 0, 0.02),
    0 16px 32px rgba(0, 0, 0, 0.02), 
    0 32px 64px rgba(0, 0, 0, 0.02);
}

.gradient-text {
  color: #0c293f;
  background-image: -webkit-linear-gradient(
    270deg,
    #3F5C72 0%,
    #0c293f 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  line-height:1.15 !important;
}

.dark .gradient-text {
  color: #ffffff;
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(25, 136, 250, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  line-height:1.15 !important;
}

.gradient-text-white {
  color: #ffffff;
  background-image: -webkit-linear-gradient(270deg, #ffffff 10%, #cccccc 90%);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  line-height:1.2;
}

.fadedMask {
  @apply overflow-hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}
