/* --- Primary --- */

.nav-primary {
  @apply max-w-7xl mx-auto p-6 hidden lg:block;
}

.nav-primary ul {
  @apply m-0 p-0 list-none flex items-center justify-center gap-12;
}

.nav-primary ul li {
  @apply m-0;
}

.nav-primary ul li a {
  @apply m-0 text-lg text-body_bg hover:text-body_bg/60 dark:text-gray-400 transition-colors font-medium dark:hover:text-white;
}

.nav-primary li.current-page-ancestor a,
.nav-primary li.current_menu_item a,
.nav-primary li.current_page_item a {
  @apply text-accent dark:text-white font-bold;
}

/* --- Secondary --- */

.nav-secondary ul {
  @apply m-0 p-0 list-none flex items-center justify-center gap-12;
}

.nav-secondary ul li {
  @apply m-0;
}

.nav-secondary ul li a {
  @apply m-0 text-base whitespace-nowrap text-body_bg hover:text-body_bg/60 dark:text-gray-400 transition-colors font-medium dark:hover:text-white;
}

.nav-secondary li.current-page-ancestor a,
.nav-secondary li.current_menu_item a,
.nav-secondary li.current_page_item a {
  @apply text-accent dark:text-white font-bold;
}

/* --- Mobile --- */

.nav-mobile ul {
  @apply m-0 p-0 list-none divide-y divide-white/10;
}

.nav-mobile ul li {
  @apply m-0;
}

.nav-mobile ul ul {
  @apply border-t border-white/10;
}

.nav-mobile ul ul li {
  @apply pl-4;
}

.nav-mobile ul li a {
  @apply block px-4 py-2.5 m-0 text-base text-white/60 transition-colors font-medium hover:text-white;
}

.nav-mobile li.current_menu_item > a,
.nav-mobile li.current_page_item > a {
  @apply text-accent dark:text-white font-bold;
}

/* --- Footer --- */

.nav-footer ul {
  @apply m-0 p-0 list-none flex items-center justify-center gap-4;
}

.nav-footer ul li {
  @apply m-0;
}

.nav-footer ul li a {
  @apply inline-block m-0 text-sm text-gray-400 hover:text-gray-500 hover:underline underline-offset-4 transition-colors font-medium dark:hover:text-white no-underline;
}

.nav-footer .current-page-ancestor a,
.nav-footer .current_menu_item a,
.nav-footer .current_page_item a {
  @apply text-accent dark:text-white font-bold;
}
