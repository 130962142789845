*,
*::after,
*::before {
  @apply box-border;
}

html,
body {
  @apply m-0 p-0 w-full min-h-[100dvh];
}

body {
  @apply antialiased overscroll-y-none text-body_bg dark:text-white bg-fixed dark:bg-body_bg bg-gradient-to-br from-white to-gray-100 dark:from-[#000000] dark:to-[#164D76] bg-center font-sans leading-snug tracking-tight;
  -webkit-tap-highlight-color: transparent;
}

