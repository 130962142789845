.scroller {
  @apply w-full;
}

.scroller__inner {
  @apply flex flex-wrap gap-8;
  padding-block: 2rem;
}

.scroller {
  @apply overflow-hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller .scroller__inner {
  @apply flex-nowrap;
  width: max-content;
  animation: scroll 30s forwards linear infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
